import { User } from '@/models/user';

export function normalizeUserName({ first_name, last_name }: User) {
  if (!first_name && !last_name) return 'not set'

  return `${first_name || ''}${last_name ? ` ${last_name}` : ''}`
}

export function normalizeUserPOA({ is_admin_poa }: User) {
  if (!is_admin_poa) return 'not set'
  return `${is_admin_poa}`
} 

export function normalizeUserPOAdate({ end_date_poa }: User) {
  if (!end_date_poa) return 'not set'
  return `${end_date_poa}`
} 

const USER_POSTFIX_MAP: { [key in keyof User]?: string } = {
  // commercial_admin_fee: '[%]',
  'is_user_poa': 'Status user',
  'is_admin_poa': 'Status admin',
  'start_date_poa': 'Start date',
  'end_date_poa': 'End date'
}
export const getPostfixByUserKey = (key: keyof User): string =>
  USER_POSTFIX_MAP[key] || ''
